import React, { Component } from 'react'
import ReactCardCarousel from "react-card-carousel";
import baby from '../images/babyw.png'
import pred from '../images/pred.png'
import natal from '../images/natal.png'

export default class MyCarousel extends Component {
  static get CONTAINER_STYLE() {
    return {
      position: "relative",
      height: "80vh",
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "middle"
    };
  }

  static get CARD_STYLE() {
    return {
      height: "500px",
      width: "320px",
       paddingTop: "50px",
      textAlign: "center",
     background: "brown",
      color: "#FFF",
      // fontFamily: "sans-serif",
      fontSize: "25pt",
      // textTransform: "uppercase",
      borderRadius: "10px",
      boxSizing: "border-box",
      cursor:"pointer"
    };
  }

  render() {
    return (
     
      <div style={MyCarousel.CONTAINER_STYLE}>
         
        <ReactCardCarousel autoplay={true} autoplay_speed={2500}>
          <div style={MyCarousel.CARD_STYLE}>Общий разбор<br/>натальной<br/>карты<br/><br/><img src={natal} width='72%' /></div>
          <div style={MyCarousel.CARD_STYLE}>Предсказательную<br/>карту<br/>на год<br/><br/><img src={pred} width='60%' /></div>
          <div style={MyCarousel.CARD_STYLE}>Детскую<br/>натальную<br/>карту<br/><br/><img src={baby} width='55%' /></div>
                    
        </ReactCardCarousel>
      </div>
    );
  }
}

