import React from 'react'

import certificate from '../images/certificate.jpeg'
import certicon from '../images/certificate-icon.png'
import ReactWhatsapp from 'react-whatsapp';
import ModalImage from "react-modal-image";
import instagram from '../images/instagramm.png'
import whatsapp from '../images/whatsapp.png'


const ContactForm = () => {

return (
    
<>
        <center><div className='title'></div></center>
        <div className='container pt-4 pr-20 pl-20 pb-4'>
        <div className="card text-center">
            <div className="card-header">
            Здравствуй! Меня зовут Тата.
            </div>
            <div className="card-body">
                <h5 className="card-title">Я выпускница Школы Астрологии Елены Литвиновой. </h5>
                <ModalImage
                    small={certicon}
                    large={certificate}
                />
                <h5 className="card-title">Я стала ведическим астрологом, чтобы помогать людям познать себя, <br/>чтоб каждый смог реализовать весь свой потенциал. </h5>
                <p className='card-text'>Хочешь узнать о своих звездно-планетарных особенностях?</p>
                
            </div>
            <div className="card-footer">
               Если да, свяжись со мной по тел.: <a href="tel:0079168879397"><span style={{fontSize: '15px'}}>+7 (916) 887-93-97</span></a>  
                    &nbsp;&nbsp;<a href='https://www.instagram.com/tatazakharova_vedzvezda/' target='_blank'><img src={instagram} width='35px' /></a>
                 &nbsp;<ReactWhatsapp number="0079168879397" ><img src={whatsapp} width='35px' /></ReactWhatsapp>
            </div>
            
        </div>
        <p></p>
        </div>
</>
   
   
  
)
}

export default ContactForm