import React from 'react'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import wisdom from '../images/target/wisdom.jpg'
import samopoznanie from '../images/target/samopoznanie.jpg'
import sozidanie from '../images/target/sozidanie.jpg'
import soul_goals from '../images/target/soul_goals.jpg'
import life from '../images/target/life.jpg'
import selfwork from '../images/target/selfwork.jpg'


export default function TargetGroup () {
    
    return (
      <div className='container pt-4 pr-20 pl-20 pb-4'>
      <center><div className='title'>Тем, кто ...</div></center>
     <br/>
<CardDeck>
  
  <Card>
    <Card.Img variant="top" src={wisdom} />
    <Card.Body>
      <Card.Text>
      доверяет мудрости планет
      </Card.Text>
    </Card.Body>

  </Card>
  <Card>
    <Card.Img variant="top" src={samopoznanie} />
    <Card.Body>
      <Card.Text>
      хочет глубже познать себя
      </Card.Text>
    </Card.Body>
   
  </Card>
  <Card>
  <Card.Img variant="top" src={soul_goals} />
    <Card.Body>
      <Card.Text>
        хочет узнать цели своей души
      </Card.Text>
    </Card.Body>
   

  </Card>
  </CardDeck>
<br />

<CardDeck> 
  <Card>
    
    <Card.Body>

      <Card.Text>
        стремится к созиданию
      </Card.Text>
    </Card.Body>
    <Card.Img variant="top" src={sozidanie} />
  </Card>
 
  


 
  <Card>
    
    <Card.Body>
      
      <Card.Text>
      запутался в жизни
      </Card.Text>
    </Card.Body>
    <Card.Img variant="top" src={life} />
  </Card>
  <Card>
    
    <Card.Body>
     
      <Card.Text>
        готов работать над собой
      </Card.Text>
    </Card.Body>
    <Card.Img variant="top" src={selfwork} />
  </Card>
  
</CardDeck>

</div>
    )
}
