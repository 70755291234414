import React, {useEffect, useState} from 'react';
import PlanetCard from './components/PlanetCard';
import TargetGroup from './components/TargetGroup';
import MyCarousel from './components/Carousel_card';
import  CardImage  from './components/CardImg';
import Tata from './images/Tata2.jpg'
import SocialBtns from './components/SocialButtons';
import ContactForm from './components/ContactForm';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Navigation, Pagination, EffectCoverflow} from 'swiper'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, EffectCoverflow])

function App() {

  const [width, setWidth] = useState({
    w: window.innerWidth,
    dif: window.outerWidth - window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () =>
      setWidth({
        w: window.innerWidth,
        dif: window.outerWidth - window.innerWidth,
      });
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const testimonials = new Array(25).fill(1);
 
  return (
  <>
      <div className='cover'>
       <img width='100%' src={Tata} />
  <center><p>Добро пожаловать<br/>в мир ведической астрологии</p></center>
       </div>
      <div className='bordo'> 
        <TargetGroup />
      </div> 
      <div className='blue'> 
      <center><div className='title'>Могу предложить...</div></center>
        <MyCarousel />
      </div> 
      <div className='bordo'>
        <CardImage />
      </div> 
      <div className='blue'>
        <PlanetCard />
      </div>
      
   
   
  
    <SocialBtns />

    <div className='bordo '>
    <center><div className='title'>Отзывы</div></center>
    <br />
    <Swiper
    effect="coverflow"
    navigation
   
    spaceBetween={20}
    slidesPerView={width.w < 1024 ? 3 : 5}
    >
       { testimonials.map((_,i) => (
        <SwiperSlide key={i+1}
        
           style={{listStyle:'none'}}
          >
           <img src={`https://vedzvezda.ru/images/testimonials/${i+1}.jpg`} width='100%'/>
          </SwiperSlide>
        
    ))}  
      </Swiper>
     </div>
    
        
     <div className='bordo'>
       <ContactForm />
     </div>

    
  </>
  );
}

export default App;
