import React,{useEffect, useState} from 'react'
import axios from "axios";
import Card from 'react-bootstrap/Card'
import moon from '../images/planets/moon.png'
import mars from '../images/planets/mars.png'
import mercury from '../images/planets/mercury.png'
import jupiter from '../images/planets/jupiter.png'
import venus from '../images/planets/venus.png'
import saturn from '../images/planets/saturn.png'
import sun from '../images/planets/sun.png'
import instagram from '../images/instagram.png'

export default function PlanetCard () {
  const weekday = new Date().getDay();
  const [dayEnergy, setDayEnergy] = useState({weekday});

  useEffect(() => {
    axios
      .get(`https://vedzvezda.ru/api/vedzvezda.php?weekday=${weekday}`)
      .then((res) => {
          console.log(res.data) 
         setDayEnergy({...res.data})
           
        
     })
     .catch(e => console.log(e.message))
}, [])

  const planets = [moon, mars, mercury, jupiter, venus, saturn, sun]

    
    return (
      <div className='planet container pt-4 pr-20 pl-20 pb-4'>
        <center><div className='title'>А сегодня день {dayEnergy.planet}</div></center>
        <br/>
          <Card className="bg-dark text-white">
          <Card.Img src={planets[weekday - 1]} />
          <Card.ImgOverlay>
           <Card.Title>
              Качества: {dayEnergy.qualities}
            </Card.Title>
            <Card.Text>Рекомендуется:</Card.Text>
            <Card.Title>
            1. Одетьcя в {dayEnergy.color} цвет.
            </Card.Title>
            <Card.Title>
            2. {weekday < 3 ? 'Питаться преимущественно ' : 'Придерживаться '} {dayEnergy.nutrition}.
            </Card.Title>
            <Card.Title>
            3. {dayEnergy.action}
            </Card.Title>
            <Card.Text></Card.Text>
            <Card.Text>Еще больше информации в <a href='https://www.instagram.com/tatazakharova_vedzvezda/' target='_blank'><img src={instagram} width='60' /></a></Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>

    )
}
