
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF,faTwitter, faGoogle, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons'
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
   
  } from "react-share";
  import {
    
    FacebookIcon,
    FacebookMessengerIcon,
    LinkedinIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WhatsappIcon,

  } from "react-share";

const SocialBtns = () => {


return (
    <>
    
    
    <div class="icon-bar">
        <div className="facebook"><FacebookShareButton url='https://vedzvezda.ru/' ><FacebookIcon size={32} round={true} /></FacebookShareButton></div>
        <div className="twitter"><TwitterShareButton url='https://vedzvezda.ru/' ><TwitterIcon size={32} round={true} /></TwitterShareButton></div>
        
        <div className="linkedin"><LinkedinShareButton url='https://vedzvezda.ru/' ><LinkedinIcon size={32} round={true} /></LinkedinShareButton></div>
        <div className="tumblr"><TumblrShareButton url='https://vedzvezda.ru/' ><TumblrIcon size={32} round={true} /></TumblrShareButton></div>
        <div className="vk"><VKShareButton url='https://vedzvezda.ru/' ><VKIcon size={32} round={true} /></VKShareButton></div>
        <div className="viber"><ViberShareButton url='https://vedzvezda.ru/' ><ViberIcon size={32} round={true} /></ViberShareButton></div>
        <div className="messenger"><FacebookMessengerShareButton appId='389630702208305' url='https://vedzvezda.ru/' ><FacebookMessengerIcon size={32} round={true} /></FacebookMessengerShareButton></div>
        <div className="telegram"><TelegramShareButton url='https://vedzvezda.ru/' ><TelegramIcon size={32} round={true} /></TelegramShareButton></div>
        <div className="whatsapp"><WhatsappShareButton url='https://vedzvezda.ru/' ><WhatsappIcon size={32} round={true} /></WhatsappShareButton></div>
    </div>
    </>
)
}




export default SocialBtns