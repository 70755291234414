import React from 'react'
import { CardView } from 'react-card-with-image'
import 'react-card-with-image/dist/index.css'
import merilinmonro from '../images/celebrities/merilinmonro_natal.jpg'
import celinedion from '../images/celebrities/celinedion_natal.jpg'
import stevejobs from '../images/celebrities/stevejobs_natal.jpg'
import alberteinstein from '../images/celebrities/alberteinstein_natal.jpg'
 
const CardImage = () => {
  const items = [
    {
      id: 1,
      header: 'Альберт Эйнштейн',
      description:
        'Карта Эйнштейна нам показывает его незаурядный ум и сильную волю, которая стремится докопаться до сути вещей, тем самым  реализовав кармическую миссию данного воплощения. Марс в экзальтации в соединении с Раху привели к тому, что гармония естественного закона открыла нам такой превосходящий Разум.',
      image: alberteinstein
    },
    {
      id: 2,
      header: 'Селин Дион',
      description:
        'По карте Селин Дион можно обратить внимание на ее голос через ретроградный Юпитер во 2-ом доме, который показывает таланты в искусстве, а также соединение Луны с Марсом в 10-ом доме карьеры, принесшему ей мировую славу и познакомивший с будущим мужем, который сыграл немалую роль в реализации ее кармической миссии.',
      image: celinedion
    },
    {
      id: 3,
      header: 'Стив Джобс',
      description:
        'Карта Стива Джобса раскрывает глубину его восприятия и сверхинтуицию, которая и помогла материализовать и подарить миру тот самый Apple и iTunes, тем самым реализовав свою кармическую миссию данного воплощения, через Венеру и Раху в 5-ом доме под управлением Юпитера и 3-им аспектом экзальтированного Сатурна.',
      image: stevejobs
    },
    {
      id: 4,
      header: 'Мерилин Монро',
      description:
        'В карте секс-символа Мерилин Монро отражаются стереотипы, связанные с ее внешностью. Но это лишь поверхностная оболочка. Кету в 12-ом доме от Луны, говорящая о сексуальности, в стрельце высушивает все эмоции. Также Луна в козероге делает очень холодной и сдержанной. Внутри она была женщиной, которая нуждалась в той самой внутренней нирване, которую достигают только через духовную сферу.',
      image: merilinmonro
    },

  ]
  return (
    <div className='container pt-4 pr-20 pl-20 pb-4'>
    <center><div className='title'>Вот например...</div></center>
    <CardView
      items={items}
      activeColor='#000'
      imageHeight='580px'
      imageWidth='290px'
    />
    </div>
  )
}
 
export default CardImage